<template>
  <v-card
    flat
    class="box-shadow-soft user-profile-post-item user-profile-story-item"
  >
    <div
      v-if="data.picture || data.preview.picture"
      class="px-3 py-2 pb-3"
    >
      <profile-chip
        :platform="data.platform"
        :data="data.picture ? data : data.preview"
      />
    </div>

    <v-img
      :src="storageUrl(data.thumbnail)"
      crossorigin="anonymous"
      height="300"
      contain
    />

    <v-card-text>
      <div class="text-center">
        {{ dayjs(data.posted_at).format("LL") }}
      </div>
    </v-card-text>

    <v-divider class="mt-3" />

    <v-card-actions class="flex-wrap px-5 py-3 post-card-stats font-weight-bold">
      <v-row class="justify-center">
        <v-col
          v-for="(item, index) in gridItems"
          :key="index"
          class="pointer"
          cols="4"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  {{ item.icon }}
                </v-icon> &nbsp;
                {{ nFormatter(item.value) }}
              </div>
            </template>

            <span>
              {{ item.value }} {{ item.tooltipText }}
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")

// Export the SFC
export default {
  // Name of the component
  name: "CRMStory",

  // Accept incoming data from parent
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  // Register children components
  components: {
    ProfileChip
  },

  // Define computed properties
  computed: {
    /**
     * Get the stat values to be shown in the grid
     *
     * @returns {Array}
     */
    gridItems() {
      return [
        {
          icon: "trending_up",
          value: this.data.reach,
          tooltipText: "Reach"
        },
        {
          icon: "visibility",
          value: this.data.impressions,
          tooltipText: "Impressions"
        },
        {
          icon: "chat_bubble",
          value: this.data.replies,
          tooltipText: "Replies"
        },
        {
          icon: "add_reaction",
          value: this.data.reactions,
          tooltipText: "Reactions"
        },
        {
          icon: "share",
          value: this.data.shares,
          tooltipText: "Shares"
        },
        {
          icon: "touch_app",
          value: this.data.sticker_taps,
          tooltipText: "Sticker Taps"
        },
        {
          icon: "link",
          value: this.data.website_clicks,
          tooltipText: "Website Clicks"
        }
      ].filter((item) => item.value)
    }
  }
}
</script>

<style lang="stylus">
.user-profile-story-item
  .v-card__text
    height unset !important
    padding-bottom 0 !important
</style>
